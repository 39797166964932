.display-container {
    padding: 0px 20px 0px 20px;
}

@media screen and (min-width: 640px) {
    .display-container {
        padding: 0px 80px 0px 80px;
    }
}

h3 {
    font-family: 'Abril Fatface';
    color: #333333;
    padding: 0px;
}

a {
    text-decoration: none; 
    font-family: 'Work Sans', sans-serif;
}