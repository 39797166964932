main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 100px;
}

@media screen and (min-width: 640px) {
    main {
        margin-top: 50px;
    }
}

h1 {
    font-family: 'Abril Fatface';
    color: #333333;
    font-size: 60px;
    text-align: center;
    margin-bottom: 5px;
}

@media screen and (min-width: 640px) {
    h1 {
        font-size: 68px;
    }
}

.landing_page_tag {
    font-family: 'Work Sans', sans-serif;
    font-size: 25px;
    text-align: center;
    margin-top: 5px;
    color: #A52A2A;
}

.login {
    width: 280px;
    margin: 0 auto;
    background-color: #A52A2A;
    border-radius: 15px;
    text-align: center;
    padding: 10px;
}

@media screen and (min-width: 640px) {
    .login {
        width: 450px;
    }
}

.auth_form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

label {
    font-family: 'Work Sans', sans-serif;
    color: white;
    justify-content: left;

}

.email_pw_input {
    width: 260px;
    border-radius: 15px;
    margin: 10px 0px;
    padding: 5px;
    align-self: center;
}

@media screen and (min-width: 640px) {
    .username_pw_input {
        width: 300px;
    }
}

.pw_suggestions {
    font-family: 'Work Sans', sans-serif;
    color: white;
    font-size: 10px;
    font-style: italic;
    list-style-type: none;
}

.auth_btn {
    font-family: 'Work Sans', sans-serif;
    border-radius: 15px;
    width: 135px;
    margin: 5px 0px 5px 0px;
    align-self: center;
    padding: 5px;
}