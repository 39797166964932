.library_placeholder {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.no_books_placeholder {
    height: 150px;
    border-radius: 17px;
}

@media screen and (min-width: 640px) {
    .no_books_placeholder {
        height: 300px;
    }
}

.placeholder-text {
    font-family: 'Work Sans', sans-serif;
    color: black;
    font-size: 17px;
    top: 89%;
}

@media screen and (min-width: 640px) {
    .placeholder-text {
        font-family: 'Work Sans', sans-serif;
        color: black;
        font-size: 30px;
        position: absolute;
        top: 89%;
    }
}

.book_list_container {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    background-color: rgba(255, 0, 0, .2);
    border-radius: 17px;
}

@media screen and (min-width: 640px) {
    .book_list_container  {
        display: flex;
        flex-direction: row;
        align-content: flex-start;
        flex-wrap: wrap;
    }
}