.book-show {
    display: flex;
    flex-direction: column;
    padding: 20px 80px;
    
}

.image {
    width: 230px;
    height: auto;
    margin: 38px;
    padding: 80px;
}

@media screen and (min-width: 640px) {
    .image {
        margin: 30px;
        width: 190px;
    }
}

.add-book-btn {
    border-radius: 30px;
    width: 120px;
    border-width: thin;
    font-family: 'Work Sans', sans-serif;
    align-self: center;
    margin: 15px 0px;
}

p {
    color: #333333;
}