.signout-contatiner {
    display: flex;
    justify-content: flex-end;
    padding: 20px 20px 0 0;
}

.signout_btn {
    font-family: 'Work Sans', sans-serif;
    border-radius: 15px;
    background: none;
    border: none;
    color: #333333;
    cursor: pointer;
}

.signout_btn:active {
    background: white;
}