.nav_container {
    background-color: #A52A2A;
}

.nav_style {
    display: flex;
    padding: 10px 20px 10px 20px;
    justify-content: space-between;
}

@media screen and (min-width: 640px) {
    .nav_style {
        justify-content: flex-end;
        padding: 10px 80px 10px 80px;
    }
}

.link_text_style {
    font-family: 'Abril Fatface', regular;
    font-size: 25px;
    list-style-type: none;
} 

@media screen and (min-width: 640px) {
    .link_text_style {
        padding-left: 20px;
    }
}

@media screen and (min-width: 640px) {
    .link_text_style  {
        font-family: 'Abril Fatface', regular;
        font-size: 25px;
        list-style-type: none;
    }
}

a:visited {
    color: #333333 !important; 
}

a.active {
        color: white !important; 
        text-decoration: underline;
}

#navlink-your-library, #navlink-add-new {
    text-decoration: none;
}

#navlink-your-library.active, #navlink-add-new.active {
    text-decoration: underline;
}