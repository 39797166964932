body, html {
    padding: 0;
    margin:0;
}

h2 {
    font-family: 'Abril Fatface', regular;
    font-size: 45px;
    color: #333333;
    padding: 0px 20px 0px 20px;
}

@media screen and (min-width: 640px) {
    h2 {
        padding: 0px 80px 0px 80px;
    }
}