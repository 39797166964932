::placeholder {
    font-family: 'Work Sans', sans-serif;
}

.searchbar_form {
    display: flex;
    flex-direction: row;
}

.search_bar_input {
    border-radius: 30px;
    margin-right: 10px;
    width: 400px;
    border-width: thin;
    padding: 5px;
}

.search_bar_btn {
    border-radius: 30px;
    width: 120px;
    border-width: thin;
    font-family: 'Work Sans', sans-serif;
}


